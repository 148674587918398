import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import "./header.css";
class Header extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  logout = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("user", "");
    localStorage.setItem("userid", "");
    localStorage.setItem("id", "");
  };

  render() {
    return (
      <nav className="navbar navbar-expand-lg">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="nav-item active">
              <a
                className="nav-link home-link"
                href="https://life-notes.netlify.com/"
              >
                HOME <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a class="nav-link" href="#">
                <Link to="/login">LOGIN</Link>
              </a>
            </li>
            <li className="nav-item">
              <a class="nav-link" href="#">
                <Link to="/activity/add">CREATE LOG</Link>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                <Link to="/list">ACTIVITIES</Link>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                <Link to="/reflections">REFLECTIONS</Link>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={this.logout}
                href="https://life-notes.netlify.com/"
              >
                LOGOUT
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Header;
